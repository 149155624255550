<template>
  <div class="grant-root filter-tab">
    <el-form
      ref="form"
      class="form"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="查找用户">
        <el-row :gutter="15">
          <el-col :span="8">
            <el-form-item prop="userName">
              <el-input
                placeholder="输入用户名称"
                v-model="form.userName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button
              :icon="Search"
              circle
              @click="getUserList(form.userName, page)"
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label-width="30px">
        <el-table
      :row-style="redCell"
      :header-row-style="headerRowStyle"
      :header-cell-style="headercellstyle"
          :data="accountList"
          style="width: 352px;--el-table-bg-color:#071f63; --el-table-border-color: none;
        --el-table-row-hover-bg-color: #0d6c9f;"
          v-loading="loading"
          stripe
          border
          size="small"
          height="256px"
          @row-click="setUser"
        >
          <el-table-column
            prop="userInfo"
            label="用户名"
            width="128"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="登录ID"
            width="96"
          ></el-table-column>
          <el-table-column prop="password" label="密码" width="128">
            <template #default="scope">
              <el-input
                show-password
                type="password"
                v-model="scope.row.password"
              >
              </el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          small
          layout="prev, pager, next"
          :total="page.total"
          :page-size="page.size"
          @current-change="changePage"
        >
        </el-pagination>
      </el-form-item>
      <!-- <el-form-item label-width="30px" style="align-item:center;">
            </el-form-item> -->
      <el-divider style="width: 500px" />
      <el-form-item label="用户名" prop="userId">
        <span>{{ user.userName }}</span>
      </el-form-item>
      <el-form-item prop="userRole">
        <el-radio-group disabled v-model="user.role">
          <el-radio label="org">企业</el-radio>
          <el-radio label="gov">政府</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="权限列表" prop="auths">
        <el-row :gutter="6">
          <el-col :span="10">
            <el-select multiple v-model="form.auths">
              <el-option
                v-for="auth in user.authorities"
                :key="auth.id"
                :value="auth.id"
                :label="auth.name"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button
              :icon="ArrowLeftBold"
              circle
              @click="addAuth()"
            ></el-button>
          </el-col>
          <el-col :span="10">
            <el-select
              placeholder="输入地区名称搜索权限"
              :loading="loading"
              default-first-option
              multiple
              remote
              filterable
              :disabled="!hasUser"
              :remote-method="getSelectPool"
              v-model="authCache"
            >
              <el-option
                v-for="auth in pool"
                :key="auth.id"
                :value="auth.id"
                :label="auth.name"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button
          class="btn-search"
          :disabled="!hasUser"
          type="primary"
          @click="submit(formRef)"
          >提交</el-button
        >
        <el-button class="btn-reset" @click="reset(formRef)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Search, ArrowLeftBold } from "@element-plus/icons-vue";
import {
  getUserInfo,
  modifyAccessAuth,
  getUserListByInfo,
} from "network/user.js";
import { getOrgDetail, getOrgWithBindByArea } from "network/org";
import { getAreaInfo, getSubareaList } from "network/area";
import { deepCopy } from "../../assets/js/utils/utils";

export default {
  name: "Grant",
  data() {
    return {
      headerRowStyle: {
        "font-size": '12px',
        height: "2vh",
        "line-height": "2vh",
        color: "black",
        border: "1px solid black",
        "background-color": "#165191",
      },
      headercellstyle: {
        "background-color": "#165191",
      },
      form: {
        userName: "",
        userRole: "",
        auths: [],
      },
      nameCache: "",
      page: { No: 1, size: 20, total: 0 },
      accountList: [],
      loading: false,
      user: {},
      hasUser: false,
      authCache: [],
      pool: [],
      selectPoolLoading: false,
      rules: {
        userName: [
          {
            required: false,
            message: "不能为空",
            trigger: "change",
          },
        ],
      },
      Search,
      ArrowLeftBold,
    };
  },
  watch: {},
  computed: {
    formRef() {
      return this.$refs.form;
    },
    getAdminAuth() {
      return [1, 2, 3];
    },
    getOrgCache() {
      return this.$store.getters["companies/userCompanies"];
    },
  },
  methods: {
    redCell: ({ row, rowIndex }) => {
      let style = {
        height: "3vh",
        fontSize: "1.5vh",
        "background-color": "#06237a",
        border: "none",
        color: "#f2f2f2",
        //'background-color':'transparent'
      };
      if (rowIndex % 2 === 0) {
        style["background-color"] = "#013380";
      }
      return style;
    },
    getSubAreasFromCache(id) {
      let result = [];
      if (id.match(/\d{2}0000/))
        result = this.$store.getters["areas/subCities"](id);
      else if (id.match(/\d{4}00/))
        result = this.$store.getters["areas/subArea"](id);
      else return;
      result.map((a) => {
        return { id: a[0], name: a[1] };
      });
    },
    getAreaIdFromCache(name) {
      if (name !== "") return this.$store.getters["areas/area"](name);
    },
    changePage(pageNo) {
      if (this.nameCache === this.form.userName) this.page.No = pageNo;
      this.getUserList(this.form.userName, this.page);
    },
    submit(form) {
      form.validate((valid) => {
        if (valid) {
          modifyAccessAuth(this.user.userId, this.form.auths).then(
            (res) => {
              this.$message.success("修改成功");
              this.searchUser(res.userName);
            },
            (e) => {
              this.$message.error("修改失败");
              console.log(e.message);
            }
          );
        }
      });
    },
    reset(form) {
      this.user = {};
      this.authCache = [];
      this.pool = [];
      this.hasUser = false;
      form.resetFields();
    },
    addAuth() {
      let pool = this.pool;
      let authCache = this.authCache;
      let formAuth = this.form.auths;
      let userAuth = this.user.authorities;
      for (let i of authCache) {
        if (typeof i == "string") {
          if (
            formAuth.findIndex((a) => {
              if (a.endsWith("0000")) return i.startsWith(a.substring(0, 2));
              else if (a.endsWith("00")) return i.startsWith(a.substring(0, 4));
              else return i === a;
            }) < 0
          ) {
            formAuth.push(i);
            userAuth.push(pool.find((a) => a.id == i));
          }
        } else {
          if (formAuth.findIndex((a) => a === i) < 0) {
            formAuth.push(i);
            userAuth.push(pool.find((a) => a.id == i));
          }
        }
      }
      this.authCache = [];
    },
    getSelectPool(query) {
      let role = this.user.role;
      let area = this.getAreaIdFromCache(query);
      if (area == undefined) return;
      this.selectPoolLoading = true;
      this.pool = [];
      if (role == "org") {
        //查询前端缓存
        // let orgCache=this.getOrgCache
        // if(orgCache[query])
        //     {
        //         this.selectPoolLoading=false
        //         this.pool=orgCache[query].map(
        //             a=>{return {'id':a.orgId,'name':a.orgName}}
        //         )
        //     }
        getOrgWithBindByArea(area[0]).then(
          (res) => {
            this.selectPoolLoading = false;
            this.pool = res.map((a) => {
              return { id: a.orgId, name: a.orgName };
            });
          },
          (e) => {
            this.selectPoolLoading = false;
            this.$message.error(e.message);
            console.log(e.message);
          }
        );
      } else {
        if (!area[0].endsWith("00")) {
          this.selectPoolLoading = false;
          return;
        }
        let pool = this.getSubAreasFromCache(area[0]);
        if (pool) {
          this.selectPoolLoading = false;
          this.pool = pool;
        } else
          getSubareaList(area[0]).then(
            (res) => {
              this.selectPoolLoading = false;
              this.pool = res.data.map((a) => {
                return { id: a.area_id, name: a.area_name };
              });
            },
            (e) => {
              this.selectPoolLoading = false;
              console.log(e.message);
              this.$message.error(e.message);
            }
          );
      }
    },
    getUserList(name, page) {
      this.formRef.validateField("userName", (valid, message) => {
        if (valid == "") {
          this.nameCache = name;
          setTimeout(() => {
            this.loading = true;
            getUserListByInfo(name, page.size, page.No).then(
              (res) => {
                this.accountList = res.dataList;
                page.size = res.pageSize;
                page.No = res.pageNum;
                page.total = res.total;
                this.loading = false;
              },
              (e) => {
                console.log(e);
                this.$message.error(e.message);
                this.loading = false;
              }
            );
          }, 1000);
        }
      });
    },
    setUser(row, column, event) {
      if (row.userName) this.searchUser(row.userName);
    },

    searchUser(searchUser) {
      this.formRef.validateField("userName", (valid, message) => {
        if (valid == "") {
          this.user = {};
          this.authCache = [];
          this.pool = [];
          getUserInfo(searchUser).then(
            (res) => {
              this.user = deepCopy(res);
              this.hasUser = true;
              if (res.authorities.length > 0) {
                if (res.role == "org")
                  getOrgDetail(res.authorities).then(
                    (res1) => {
                      this.user.authorities = res1.data.map((a) => {
                        return { id: a.org_id, name: a.org_name };
                      });
                      this.form.auths = res.authorities.map((a) =>
                        Number.parseInt(a)
                      );
                    },
                    (e) => {
                      console.log(e.message);
                    }
                  );
                else {
                  getAreaInfo(res.authorities).then(
                    (res1) => {
                      this.user.authorities = res1.data.map((a) => {
                        return { id: a.area_id, name: a.area_name };
                      });
                      this.form.auths = res.authorities;
                    },
                    (e) => {
                      console.log(e.message);
                    }
                  );
                }
              }
            },
            (e) => {
              this.user.userName = e.message;
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.grant-root {
  width: 800px;

  .el-table__body-wrapper::-webkit-scrollbar {
    width: 0px !important;
  }
  .el-form {
    padding-top: 20px;
    align-items: center;
    padding-left: 50px;
  }
}
.table-area /deep/ .el-table__body-wrapper {
  background-color: #06237a;
}

.filter-tab /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
.filter-tab /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
.filter-tab /deep/ input {
  color: #f2f2f2;
}
//按钮样式
.btn-search {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763ff;
  border-radius: 5px;
}
.btn-reset {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763ff;
  color: white;
  border-radius: 5px;
}
</style>